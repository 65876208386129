import { useLogo } from "../components/Layout"

import "../css/Collaborations.css"

export default function Collaborations() {

  const { botlandType } = useLogo();

  return (<>
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <div className="text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
          Współprace
        </h1>
        <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
          Jesteśmy otwarci na współpracę z różnymi podmiotami studenckimi czy agendami kultury. W przeszłości oświetlaliśmy wiele budynków we Wrocławiu na rzecz przeróżnych inicjatyw m.in. finał WOŚP w budynku Uniwersytetu Przyrodniczego, budynek Dolnośląskiego Urzędu Wojewódzkiego na czas Narodowego Święta Niepodległości, Robotic Arana w Hali Stulecia czy Szalona Noc Naukowa Uniwersytetu Przyrodniczego. Ponad to, mamy za sobą kilka szkoleń dla dzieci i młodzieży w zakresie podstaw elektroniki i programowania.
        </p>
      </div>
    </div>
    <div
      aria-hidden="true"
      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#e8ff38] to-[#37ff00] dark:from-[#ff3aef] dark:to-[#3030ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      />
    </div>

    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <div className="text-center">
        <div id="image-middle">
          <img src={botlandType} />
        </div>
        <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
          Miło nam oznajmić, że od drugiej połowy 2021 roku, rozpoczęliśmy współpracę z jednym z najbardziej rozpoznawalnych sklepów dla elektroników i robotyków – firmą Botland.
        </p>
      </div>
    </div>
    <div
      aria-hidden="true"
      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#f2ff35] to-[#0dff00] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      />
    </div>

  </>)
}