

export default function Error404() {
  return (<>
    <main className="grid min-h-full place-items-center bg-white dark:bg-slate-800 px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-green-500 dark:text-pink-500">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">Nie znaleziono strony</h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">Przykro nam, nie byliśmy w stanie znaleźć strony, której szukasz.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-black dark:text-white shadow-sm hover:text-green-500 dark:hover:text-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:text-green-500"
          >
            Wróć na stronę startową
          </a>
        </div>
      </div>
    </main>
  </>)
}