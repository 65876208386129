import { db } from '../config/FirebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { useState, useEffect } from "react";
import ReactPlayer from 'react-player';

import "../css/PreviousEditions.css";
import "../css/Home.css"

export default function PreviousEditions() {
    const [previousEditions, setPreviousEditions] = useState([]);

    const fetchPreviousEditions = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "showHistory"));
            const previousEditions = querySnapshot.docs.map(doc => {
                const data = doc.data();
                const songsWithIds = data.songs ? data.songs.map((song, index) => (
                    {
                        id: index + 1,  // Use uuid for unique ids, or index for simple ids
                        name: song
                    })) : [];

                return {
                    id: data.id,
                    showName: data.showName,
                    videoLink: data.videoLink,
                    songs: songsWithIds
                };
            });

            const sortedData = previousEditions.sort((a, b) => b.id - a.id);

            setPreviousEditions(sortedData);  // Update the state with the data including the songs with ids
        } catch (error) {
            console.error("Error fetching previous editions:", error);
        }
    };

    useEffect(() => {
        fetchPreviousEditions();
    }, []);

    return (
        <>
            <div className="mt-14 mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Poprzednie edycje projektu P.I.W.O.</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Niedawne pokazy projektu P.I.W.O. wraz z poszczególnymi utworami</p>
                </div>
            </div>
            {previousEditions.map(item => (
                <div key={item.id} className="card overflow-hidden bg-white dark:bg-slate-800">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="lg:pr-8 lg:pt-4">
                                <div className="lg:max-w-lg">
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">{item.showName}</p>
                                    <div className='songs'>
                                        <table key={item.id}>
                                            <thead>
                                                <tr>
                                                    <th className='dark:text-gray-400'>Playlista</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.songs.map(song => (
                                                    <tr key={song.id}>
                                                        <td className='dark:text-white'>{song.id + ". " + song.name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="poster-div">
                                <ReactPlayer url={item.videoLink} className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}