import { db } from '../config/FirebaseConfig';
import { getDownloadURL } from "firebase/storage";
import { getDocs, collection } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
import { useState, useEffect } from "react"
import { useLogo } from "../components/Layout";

export default function Team() {

  const [management, setManagement] = useState([]);
  const [members, setMembers] = useState([]);
  const { unknownType } = useLogo();

  const fetchManagementPositions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "teamManagement"));
      const managementPositions = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const managementWithImages = await Promise.all(managementPositions.map(async (item) => {
        try {
          const storage = getStorage();
          const url = await getDownloadURL(ref(storage, "team/" + item.image));
          return { ...item, image: url };  // Return the item with the image URL
        } catch (error) {
          console.error("Error fetching image URL:", error);
          return { ...item, image: null };  // Still return the item, but with a null image URL in case of error
        }
      }));

      const sortedData = managementWithImages.sort((a, b) => a.id - b.id);

      setManagement(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching management positions:", error);
    }
  };

  const fetchMembers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "teamMOS"));
      const members = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const sortedData = members.sort((a, b) => a.id - b.id);

      setMembers(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    fetchManagementPositions();
    fetchMembers();
  }, []);

  return (<>
    <div className="bg-white dark:bg-slate-800 card">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Poznaj nasz zespół!</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
            Z nami nie ma rzeczy niemożliwych!
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {management.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img alt="" src={person.image} className="h-16 w-16 rounded-full" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">{person.role}</p>
                  <h3 className="text-base leading-7 tracking-tight text-gray-900 dark:text-white">{person.email}</h3>
                  <h3 className="text-base leading-7 tracking-tight text-gray-900 dark:text-white">{person.phoneNumber}</h3>
                </div>
              </div>
            </li>
          ))}
          {members.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img alt="" src={unknownType} className="h-16 w-16 rounded-full" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-white">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-green-500 dark:text-cyan-400">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </>)
}