import manus from "../assets/manus.png"
import kmeif from "../assets/kmeif.jpg"
import pwr from "../assets/pwr.png"
import botland from "../assets/botland.png"
import piwo from "../assets/piwo.png"
import sapwr from "../assets/sapwr.png"
import facebook from "../assets/facebook.png"
import instagram from "../assets/instagram.png"
import email from "../assets/email.png"
import facebookContact from "../assets/facebook_contact.png"
import instagramContact from "../assets/instagram_contact.png"
import unknown from "../assets/unknown.png"

import logo from "../assets/mos_logo.png"
import logoDark from "../assets/mos_logo_dark.png"

import botlandDark from "../assets/botland_dark.png"
import manusDark from "../assets/manus_dark.png"
import pwrDark from "../assets/pwr_dark.png"
import kmeifDark from "../assets/kmeif_dark.png"
import sapwrDark from "../assets/sapwr_dark.png"
import piwoDark from "../assets/piwo_dark.png"
import downloadDark from "../assets/download_dark.png"
import instagramDark from "../assets/instagram_dark.png"
import facebookDark from "../assets/facebook_dark.png"
import emailDark from "../assets/email_dark.png"
import unknownDark from "../assets/unknown_dark.png"

import beer from "../assets/beer.png"
import couch from "../assets/couch.png"
import clock from "../assets/clock.png"
import arcadeMachine from "../assets/arcade-machine.png"
import laser from "../assets/laser.png"

import play from "../assets/play.svg"
import arrow from "../assets/arrow.png"
import info from "../assets/info.png"
import question from "../assets/question.png"
import collab from "../assets/collab.png"
import download from "../assets/download.png"
import member from "../assets/member.png"
import star from "../assets/star.png"
import former from "../assets/former.png"

import moon from "../assets/moon.png"
import sun from "../assets/sun.png"

import { Link, useNavigate } from 'react-router-dom';

import { useState, createContext, useContext, useEffect, useRef } from 'react'
import {
  Button,
  CloseButton,
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, } from '@heroicons/react/20/solid'

import '../css/Navbar.css'

const solutionsPIWO = [
  { name: 'Poprzednie edycje', description: 'Zobacz jak wyglądały poprzednie edycje projektu P.I.W.O.', href: '/previous-editions', icon: arrow },
  { name: 'O projekcie', description: 'Dowiedz się o historii projektu, zasadzie działania i nie tylko', href: '/piwo-project', icon: info },
  { name: 'Q&A', description: "Odpowiedzi na najczęściej zadawane pytania", href: '/q-and-a', icon: question },
  { name: 'Współprace', description: 'Sprawdź z kim współpracujemy', href: '/collaborations', icon: collab },
  { name: 'Odtwarzacz animacji', description: 'Sprawdź jak będzie wyglądała Twoja animacja na fikcyjnym budynku', href: '/piwo-player', icon: play },
  { name: 'Pobierz edytor animacji', description: 'Stwórz własną animację', href: '/download', icon: download }
]

const solutionsProjects = [
  { name: 'Projekt P.I.W.O.', description: 'Najbardziej znany projekt w kole', href: '/piwo-project', icon: beer },
  { name: 'Projektor Laserowy RGB 7W', description: 'Laser urozmaicający pokazy P.I.W.O.', href: '/laser', icon: laser },
  { name: 'Szalona kanapa', description: "Coś szalonego!", href: '/crazy-couch', icon: couch },
  { name: 'Big Binary Clock', description: 'Sławny zegar z budynku C-13 PWr', href: '/big-binary-clock', icon: clock },
  { name: 'Arcade Machine', description: 'Automat rodem z salonów gier', href: '/arcade-machine', icon: arcadeMachine },
]

const solutionsTeam = [
  { name: 'Obecni członkowie', description: 'Poznaj aktualnych członków koła', href: '/team', icon: member },
  { name: 'Ściana sław', description: 'Najbardziej zasłużeni członkowie koła', href: '/distinguished', icon: star },
  { name: 'Byli członkowie', description: "Poprzedni członkowie MOSu", href: '/former-members', icon: former },
]

const LogoContext = createContext();

export function Layout({ children }) {
  const [logoType, setLogoType] = useState({});
  const [botlandType, setBotlandType] = useState({});
  const [manusType, setManusType] = useState({});
  const [pwrType, setPwrType] = useState({});
  const [kmeifType, setKmeifType] = useState({});
  const [sapwrType, setSapwrType] = useState({});
  const [piwoType, setPiwoType] = useState({});
  const [downloadType, setDownloadType] = useState({});
  const [instagramType, setInstagramType] = useState({});
  const [facebookType, setFacebookType] = useState({});
  const [emailType, setEmailType] = useState({});
  const [unknownType, setUnknownType] = useState({});

  const [symbol, setSymbol] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkTheme();
  }, []);

  const changeTheme = () => {
    if (!localStorage.getItem("darkMode") || localStorage.getItem("darkMode") === "false") {
      localStorage.setItem("darkMode", true);
      setSymbol(sun);
      setLogoType(logoDark);
      setBotlandType(botlandDark);
      setManusType(manusDark);
      setPwrType(pwrDark);
      setKmeifType(kmeifDark);
      setSapwrType(sapwrDark);
      setPiwoType(piwoDark);
      setDownloadType(downloadDark);
      setEmailType(emailDark);
      setFacebookType(facebookDark);
      setInstagramType(instagramDark);
      setUnknownType(unknownDark);
      document.documentElement.classList.add("dark");
    }

    else {
      localStorage.setItem("darkMode", false);
      setSymbol(moon);
      setLogoType(logo);
      setBotlandType(botland);
      setManusType(manus);
      setPwrType(pwr);
      setKmeifType(kmeif);
      setSapwrType(sapwr);
      setPiwoType(piwo);
      setDownloadType(download);
      setEmailType(email);
      setFacebookType(facebookContact);
      setInstagramType(instagramContact);
      setUnknownType(unknown);
      document.documentElement.classList.remove("dark");
    }
  }

  function checkTheme() {
    if (localStorage.getItem("darkMode") === "false") {
      setSymbol(moon);
      setLogoType(logo);
      setBotlandType(botland);
      setManusType(manus);
      setPwrType(pwr);
      setKmeifType(kmeif);
      setSapwrType(sapwr);
      setPiwoType(piwo);
      setDownloadType(download);
      setEmailType(email);
      setFacebookType(facebookContact);
      setInstagramType(instagramContact);
      setUnknownType(unknown);
      document.documentElement.classList.remove("dark");
    }

    else {
      setSymbol(sun);
      setLogoType(logoDark);
      setBotlandType(botlandDark);
      setManusType(manusDark);
      setPwrType(pwrDark);
      setKmeifType(kmeifDark);
      setSapwrType(sapwrDark);
      setPiwoType(piwoDark);
      setDownloadType(downloadDark);
      setEmailType(emailDark);
      setFacebookType(facebookDark);
      setInstagramType(instagramDark);
      setUnknownType(unknownDark);
      document.documentElement.classList.add("dark");
    }
  }

  function refresh(element){
    navigate(element);
    window.location.reload();
  }

  function closeMobileNavbar(element){
    setMobileMenuOpen(false);
    navigate(element);
    window.location.reload();
  }

  return (<>
    <header className="bg-white dark:bg-slate-900">
      <nav aria-label="Global" className="mx-auto flex items-center justify-between p-6 lg:px-8">
        <div className="flex">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">SKN MOS</span>
            <img alt="" src={logoType} className="h-8 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white">
              Projekt P.I.W.O.
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-50 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-slate-800 shadow-lg ring-1 ring-gray-900/5 dark:ring-white transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {solutionsPIWO.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-slate-500"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <img alt="solution-image" src={item.icon} aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div className="flex-auto">
                      <Link to={item.href} onClick={() => refresh(item.href)} className="block font-semibold text-gray-900 dark:text-white">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
        </PopoverGroup>

        <Link to="/blog" className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          Blog
        </Link>

        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white">
              Nasze projekty
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-50 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-slate-800 shadow-lg ring-1 ring-gray-900/5 dark:ring-white transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {solutionsProjects.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-slate-500"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <img alt="solution-image" src={item.icon} aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div className="flex-auto">
                      <Link to={item.href} onClick={() => refresh(item.href)} className="block font-semibold text-gray-900 dark:text-white">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
        </PopoverGroup>

        <Link to="/photo-gallery" className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          Galeria
        </Link>

        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white">
              Zespół
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-50 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-slate-800 shadow-lg ring-1 ring-gray-900/5 dark:ring-white transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {solutionsTeam.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-slate-500"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <img alt="solution-image" src={item.icon} aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div className="flex-auto">
                      <Link to={item.href} onClick={() => refresh(item.href)} className="block font-semibold text-gray-900 dark:text-white">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-gray-600 dark:text-gray-400">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
        </PopoverGroup>

        <Link to="/recruitment" className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          Rekrutacja
        </Link>

        <Link to="/contact" className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          Kontakt
        </Link>
        <button onClick={changeTheme}><img alt="change-theme" src={symbol}></img></button>
      </nav>

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white dark:bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src={logoType}
                className="h-8 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => closeMobileNavbar(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400">
                    Projekt P.I.W.O.
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...solutionsPIWO].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  to="/blog"
                  onClick={() => closeMobileNavbar('/blog')}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                >
                  Blog
                </Link>

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400">
                    Nasze projekty
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...solutionsProjects].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  to="/photo-gallery"
                  onClick={() => closeMobileNavbar('/photo-gallery')}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                >
                  Galeria
                </Link>

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400">
                    Zespół
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...solutionsTeam].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  to="/recruitment"
                  onClick={() => closeMobileNavbar('/recruitment')}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                >
                  Rekrutacja
                </Link>

                <Link
                  to="/contact"
                  onClick={() => closeMobileNavbar('/contact')}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-400"
                >
                  Kontakt
                </Link>
                <button onClick={changeTheme}><img alt="change-theme" src={symbol}></img></button>

              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <LogoContext.Provider value={{ logoType, piwoType, botlandType, downloadType, emailType, instagramType, facebookType, unknownType }}>
      <main>
        {children}
      </main>
    </LogoContext.Provider>

    <footer>
      <div className="bg-green-600 dark:bg-cyan-400" id="social-media">
        <h2 id="info" className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Zaobserwuj nas na naszych social mediach, aby nie przegapić aktualności!</h2>
        <div id="social-media-links">
          <a className="social-picture" href="https://www.facebook.com/MOSPWr"><img alt="facebook" src={facebook} /></a>
          <a className="social-picture" href="https://www.instagram.com/skn.mos/"><img alt="instagram" src={instagram} /></a>
        </div>
      </div>
      <div className="bg-white dark:bg-black" id="sponsors">
        <div className="sponsors-1">
          <a className="sponsor" href="https://botland.com.pl/"><img alt="sponsor" src={botlandType} /></a>
          <a className="sponsor" href="https://pwr.edu.pl/"><img alt="sponsor" src={pwrType} /></a>
          <a className="sponsor" href="https://kmeif.pwr.edu.pl/"><img alt="sponsor" src={kmeifType} /></a>
        </div>
        <div className="sponsors-2">
          <a className="sponsor" href="https://manus.pl/"><img alt="sponsor" src={manusType} /></a>
          <a className="sponsor" href="/"><img alt="sponsor" src={piwoType} /></a>
          <a className="sponsor" href="https://absolwent.pwr.edu.pl/"><img alt="sponsor" src={sapwrType} /></a>
        </div>
      </div>
      <div className="info text-gray-600 dark:text-gray-400">
        <p>Icons by <a href="https://icons8.com" className="underline">Icons8</a></p>
      </div>
    </footer>
  </>)
}

export const useLogo = () => useContext(LogoContext);