import beer from "../assets/beer.png"
import couch from "../assets/couch.png"
import clock from "../assets/clock.png"
import arcadeMachine from "../assets/arcade-machine.png"
import laser from "../assets/laser.png"
import poster from "../assets/poster.jpg"

import '../css/Home.css'

import { useLogo } from "../components/Layout";

export default function Home() {

  const { logoType } = useLogo();

  return (<>
    <div className="card bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl flex flex-col items-center lg:text-center">
          <div>
            <img id="huge-logo" src={logoType} />
          </div>
          <br />
          <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Cześć!</p>
          </div>
          <div>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Znajdujesz się na oficjalnej stronie internetowej Studenckiego Koła Naukowego: Microsystems Oriented Society.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="card overflow-hidden bg-white dark:bg-slate-800">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Kim jesteśmy?</p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Jesteśmy studenckim kołem naukowym działającym przy Politechnice Wrocławskiej, które zajmuje się tworzeniem projektów z zakresu szeroko rozumianej elektroniki i programowania, ale przede wszystkim zrzeszamy ludzi, którzy chcą od studiów czegoś więcej niż zajęcia na uczelni – pragną się rozwijać i są gotowi przekuwać swoje pomysły i idee w działające urządzenia czy oprogramowanie.

                Nie straszne są nam systemy embedded, projektowanie płytek PCB, tworzenie aplikacji czy realizacja projektów łączących ze sobą dziedziny elektroniki i mechaniki.

                Zainteresowany? Aplikuj lub skontaktuj się z nami!</p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <img className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" src={beer} />
                    Projekt P.I.W.O.
                  </dt>
                  <dd className="inline dark:text-gray-400"> Projekt polegający na zamianie budynku akademika w Potężny Indeksowany Wyświetlacz Oknowy</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <img className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" src={couch} />
                    Szalona kanapa
                  </dt>
                  <dd className="inline dark:text-gray-400"> Coś szalonego!</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <img className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" src={laser} />
                    Projektor laserowy RGB 7W
                  </dt>
                  <dd className="inline dark:text-gray-400"> Urozmaicenie każdego pokazu P.I.W.O. poprzez wyświetlanie animacji na budynku</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <img className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" src={clock} />
                    Big Binary Clock
                  </dt>
                  <dd className="inline dark:text-gray-400"> Ozdoba znanego wszystkim "serowca" - budynku C-13 PWr</dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900 dark:text-white">
                    <img className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" src={arcadeMachine} />
                    Arcade Machine
                  </dt>
                  <dd className="inline dark:text-gray-400"> Odwzorowanie maszyny znanej m.in. z salonów gier</dd>
                </div>
              </dl>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">I przyłącz się do jednego z powyższych projektów bądź zaproponuj swój własny!</p>
            </div>
          </div>
          <div id="poster-div">
            <img id="poster" src={poster} alt="Product screenshot" className="w-[48rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
          </div>
        </div>
        <a href="/team" className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Zespół</a>
        <a href="/recruitment" className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Rekrutacja</a>
        <a href="/contact" className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Kontakt</a>
      </div>
    </div>
  </>)
}