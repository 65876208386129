import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import PIWO from './pages/PIWO';
import PhotoGallery from './pages/PhotoGallery';
import OurProjects from './pages/OurProjects';
import Team from './pages/Team';
import Recruitment from './pages/Recruitment';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import { Layout } from './components/Layout';
import PreviousEditions from './pages/PreviousEditions';
import Download from './pages/Download';
import PIWOPlayer from './pages/PIWOPlayer';
import Collaborations from './pages/Collaborations';
import QAndA from './pages/QAndA';
import Error404 from './pages/Error404';
import Laser from './pages/Laser';
import CrazyCouch from './pages/CrazyCouch';
import BigBinaryClock from './pages/BigBinaryClock';
import ArcadeMachine from './pages/ArcadeMachine'
import WallOfFame from './pages/WallOfFame'
import FormerMembers from './pages/FormerMembers'

function App() {

  return (
    <>
      <BrowserRouter basename='/'>
        <Layout>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/piwo-project' element={<PIWO />}></Route>
            <Route path='/blog' element={<Blog />}></Route>
            <Route path='/our-projects' element={<OurProjects />}></Route>
            <Route path='/photo-gallery' element={<PhotoGallery />}></Route>
            <Route path='/team' element={<Team />}></Route>
            <Route path='/distinguished' element={<WallOfFame />}></Route>
            <Route path='/former-members' element={<FormerMembers />}></Route>
            <Route path='/recruitment' element={<Recruitment />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path='/previous-editions' element={<PreviousEditions />}></Route>
            <Route path='/download' element={<Download />}></Route>
            <Route path='/piwo-player' element={<PIWOPlayer />}></Route>
            <Route path='/collaborations' element={<Collaborations />}></Route>
            <Route path='/q-and-a' element={<QAndA />}></Route>
            <Route path='/laser' element={<Laser />}></Route>
            <Route path='/crazy-couch' element={<CrazyCouch />}></Route>
            <Route path='/big-binary-clock' element={<BigBinaryClock />}></Route>
            <Route path='/arcade-machine' element={<ArcadeMachine />}></Route>
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  )
}

export default App;
