
import { db } from '../config/FirebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { useState, useEffect } from "react"

import trophy from "../assets/trophy.png"

export default function WallOfFame() {

  const [distinguished, setDistinguished] = useState([]);

  const fetchDistinguished = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "distinguished"));
      const distinguished = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        ...doc.data(),
        index,  // Capture the index here if needed later
      }));

      const sortedData = distinguished.sort((a, b) => a.id - b.id);

      setDistinguished(sortedData);  // Update the state with the recruitment data including the images
    } catch (error) {
      console.error("Error fetching distinguished people:", error);
    }
  };

  useEffect(() => {
    fetchDistinguished();
  }, []);

  return (<>
    <div className='p-12'>
      <div className='flex justify-center'>
        <img src={trophy}></img>
      </div>
      <div className="flex justify-center px-4 sm:px-0">
        <h1 className="text-base font-semibold leading-7 text-green-500 dark:text-cyan-400">Członkowie SKN MOS zasługujący na wyróżnienie</h1>
      </div>
      <div className="flex justify-center mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {distinguished.map(item => (
            <div key={item.id} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">{item.name}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">{item.cause}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </>)
}