import laser1 from "../assets/laser-1.jpg"
import laser2 from "../assets/laser-2.jpg"
import laser3 from "../assets/laser-3.jpg"
import laser4 from "../assets/laser-4.jpg"
import laser5 from "../assets/laser-5.jpg"
import laser6 from "../assets/laser-6.jpg"
import laser7 from "../assets/laser-7.jpg"
import laser8 from "../assets/laser-8.jpg"

import "../css/Laser.css"

const ProsList = () => {
    return (
        <ul id="pros">
            <li>Najnowsza 3.5W dioda laserowa firmy Nichia</li>
            <li>To również ta firma wypuściła na rynek absolutny hit, czyli zieloną diodę laserową o mocy 1W</li>
            <li>Niestety diody o tak dużych mocach posiadają większe struktury, co jest okupione gorszymi parametrami wiązki laserowej</li>
            <li>Dla nas najistotniejszym z nich jest dywergencja (rozbieżność wiązki)</li>
            <li>Idealna wiązka powinna być równoległa, niestety w przypadku wyżej wymienionych diod nie tylko nie jest równoległa, ale rozbiega się różnie w poziomie i pionie</li>
            <li>Stąd wymagane jest użycie specjalnej optyki minimalizującej tą wadę, na przykład dodatkowego układu pryzmatów, co też zastosowaliśmy w naszym projektorze</li>
        </ul>
    );
};

const RedLightList = () => {
    return (
        <div>
            <p>Z diodą czerwoną wiązało się dodatkowe wyzwanie. </p>
            <ul id="pros">
                <li>Niestety najmocniejsza dioda dostępna na rynku, która nadaje się do projektora laserowego ma 700mW mocy optycznej</li>
                <li>Owszem jest też dioda o mocy 4W, co byłoby dla nas ideałem, gdyby nie pewien kluczowy parametr. Niestety dioda ta ma bardzo dużą rozbieżność i nie da się uzyskać wiązki dobrej jakości, bez zastosowania gigantycznych rozmiarów optyki. To trochę kłóci się z założeniami naszego „małego” projektora</li>
                <li>Musieliśmy więc podejść do tematu inaczej. Połączyliśmy 3 czerwone diody laserowe o mocy 700mW każda, metodą Knife Edging. Dało to sumarycznie 2.1W czerwieni</li>
                <li>Docelowo chcemy uzyskać przynajmniej 3.5W, a najlepiej nieco ponad 4W, ale musimy jeszcze popracować nad jakością wiązki.</li>
            </ul>
        </div>
    );
};

const DriversList = () => {
    return (
        <div>
            <p>Tak jak wspomniano wyżej, do napędzania laserów użyliśmy driverów własnej konstrukcji. Zresztą trudno sobie wyobrazić żeby było inaczej, w końcu jesteśmy w głównej mierze kołem elektroników!</p>
            <ul id="pros">
                <li>Projekt drivera jest bardzo uniwersalną konstrukcją, co pozwala na pracę z diodami o wszystkich kolorach</li>
                <li>Warto nadmienić, że różnią się one zarówno napięciem przewodzenia jak i prądem progowym oraz maksymalnym</li>
                <li>Driver posiada szereg regulacji pozwalających na optymalne dostrojenie się do zastosowanej diody laserowej</li>
                <li>Na wejście sterujące podawany jest sygnał różnicowy, którym można modulować laser do częstotliwości 100kHz</li>
                <li>Dodatkowo driver wyposażono w wejście shutdown, pozwalające natychmiast wyłączyć diodę</li>
                <li>Trzeba mieć na uwadze, że moc jaką dysponują nasze diody plasuje się w najwyższej, czyli IV klasie bezpieczeństwa promieniowania laserowego</li>
                <li>Wiązka takiego lasera, nawet odbita, z łatwością może uszkodzić wzrok</li>
            </ul>
        </div>
    );
}

const features = [
    { name: '1. Uzyskanie kolorowej animacji', description: 'Aby uzyskać kolorową animację za pomocą projektora, musimy połączyć trzy diody laserowe emitujące podstawowe kolory czerwony, zielony, niebieski w jedną wspólną wiązkę' },
    { name: '2. Wiązka trafia na układ skanujący', description: 'Układ skanujący umożliwia jej odchylanie w poziomie i pionie. Poprzez bardzo szybkie skanowanie możemy wyświetlać animacje bądź efekty świetlne w zadymionym powietrzu' },
    { name: '3. Źródło niebieskiego światła', description: <ProsList /> },
    { name: '4. Źródło czerwonego światła', description: <RedLightList /> },
    { name: '5. Dobór mocy laserów', description: 'Ma to związek z charakterystyką ludzkiego oka. Odpowiednio dobrane jasności pozwalają otrzymać białe światło bez przebarwień. Dla długości fali naszych diod laserowych najlepszy stosunek R:G:B dla neutralnej białej barwy to 4:1:3. Stąd też wystarczy nam 1W zieleni , ale potrzebujemy aż 4W czerwonego.' },
    { name: '6. Drivery', description: <DriversList /> },
    { name: '7. Biała wiązka', description: "Białą wiązkę uzyskuje się poprzez połączenie wiązek RGB z poszczególnych modułów laserowych. Jest to realizowane poprzez zwierciadła dichroiczne, które odbijają bądź przepuszczają światło w zależności od długości fali. Zwierciadła te umieszczone są na specjalnych, wykonanych przez nas mocowaniach kinematycznych, pozwalających na regulację kąta nachylania w poziomie i pionie. Dzięki temu można precyzyjnie regulować zbieżność poszczególnych wiązek względem siebie" },
    { name: '8. Galvo', description: "Ostatnim etapem jest doprowadzenie zsumowanych wiązek na układ skanerów galvo. Zastosowaliśmy najszybsze na świecie galva Cambridge Technology, dzięki czemu możemy płynnie wyświetlać nawet bardzo skomplikowane animacje. Galva posiadają również szerokie kąty skanowania, co pozwala na projekcję na dużych powierzchniach ze stosunkowo małej odległości." },
]

export default function Laser() {

    return (<>
        <div className="overflow-hidden bg-white dark:bg-slate-800 card">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Projektor laserowy RGB 7W</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Pomysł wykonania projektora laserowego przewijał się już od jakiegoś czasu w naszym kole. I raczej pozostałby w naszych głowach, gdyby nie pewien przełomowy moment – mianowicie pojawienie się na rynku zielonej diody laserowej dużej mocy. Był to ostatni element potrzebny do uzyskania pełnej palety barw z wykorzystaniem tylko i wyłącznie laserów półprzewodnikowych. Od kilku lat na rynku dostępne są niebieskie i czerwone diody laserowe o mocach wystarczających do projekcji na dużych budynkach. Dlaczego tak bardzo zależało nam na diodach laserowych? Powodów jest kilka. Diody laserowe cechują się:</p>
                            <ul id="pros">
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">dużą efektywnością</li>
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">małymi rozmiarami</li>
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">nie są tak skomplikowane (przez co też tańsze) jak moduły laserowe innego typu.</li>
                            </ul>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Dzięki tym zaletom zbudowaliśmy projektor o dużej mocy przy zachowaniu małych wymiarów. Zaprojektowanie własnych driverów do sterowania prądem laserów również pozwoliło na miniaturyzację całości. Dzięki wsparciu finansowemu Działu Studenckiego mogliśmy zastosować wysokiej jakości komponenty, czyniąc w ten sposób nasz projektor profesjonalnym urządzeniem.</p>
                        </div>
                    </div>
                    <div className="image-div">
                        <img
                            src={laser1}
                            alt="Product screenshot"
                            className="piwo-image w-auto mb-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        />
                        <img
                            src={laser2}
                            alt="Product screenshot"
                            className="piwo-image w-auto mt-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white dark:bg-slate-800">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h1 className="text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Konstrukcja</h1>
                    <p className="mt-4 text-gray-500 dark:text-white">
                        Same lasery to jeszcze nie wszystko:
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900 dark:text-white">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500 dark:text-gray-400">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="grid grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                    <img
                        alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        src={laser3}
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        alt="Top down view of walnut card tray with embedded magnets and card groove."
                        src={laser6}
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        alt="Side of walnut card tray with card groove and recessed card area."
                        src={laser7}
                        className="rounded-lg bg-gray-100"
                    />
                </div>
            </div>
        </div>

        <div className="overflow-hidden bg-white dark:bg-slate-800 card">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none">
                    <div className="lg:pr-8 lg:pt-4 flex flex-col justify-center">
                        <div className="lg:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">
                                Bezpieczeństwo przede wszystkim
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                W tym momencie większość osób stwierdzi, że tor optyczny jest kompletny. Ale nie tak prędko.
                            </p>
                            <ul id="pros">
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                    Bardzo ważnym elementem projektorów dużej mocy jest Shutter, czyli układ blokujący wiązkę laserową w razie wykrycia usterki, np. zatrzymania się skanerów galvo w trakcie pracy laserów.
                                </li>
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                    Tu warto wspomnieć o normie MDE czyli Maksymalnej Dopuszczalnej Ekspozycji (ang. MPE). Określa ona m.in. maksymalną bezpieczną moc optyczną wiązki lasera w zależności od długości ekspozycji, długości fali oraz ilości powtórnych naświetleń.
                                </li>
                                <li className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                    Spełnienie wszystkich kryteriów pozwala na bezpieczne stosowanie projektora podczas pokazów w stronę publiczności, a więc takich, gdzie wiązka może bezpośrednio padać na oko.
                                </li>
                            </ul>
                            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                                Obliczenia są dość skomplikowane, ale w skrócie można powiedzieć, że szybko poruszająca się wiązka po odpowiednio dużej powierzchni jest bezpieczna dla oka. Stąd też konieczność stosowania Shuttera. Niestety prosty układ, który sprawdza tylko czy galva się ruszają tylko pozornie zapewnia bezpieczeństwo. Tak jak wspomniałem wyżej, trzeba uwzględniać wiele parametrów zdefiniowanych w normie MDE. Stosując taki zwykły Shutter musimy sami obliczać bezpieczną odległość i obszar skanowania przed każdym pokazem. Stąd też narodziła się idea inteligentnego układu, który będzie na bieżąco sprawdzał szybkość poruszania się wiązki (na podstawie sygnału zwrotnego ze skanerów galvo) i uwzględniając odległość projektora od publiczności, ograniczał w razie potrzeby, w czasie rzeczywistym moc laserów.
                            </p>
                        </div>
                    </div>
                    <div className="image-div flex flex-col justify-center">
                        <img
                            src={laser4}
                            alt="Product screenshot"
                            className="piwo-image w-auto mb-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        />
                        <img
                            src={laser8}
                            alt="Product screenshot"
                            className="piwo-image w-auto mt-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="">
                <br />
                <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Sterowanie</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Sterowanie całością odbywa się przy pomocy oprogramowania i karty firmy Pangolin. Jest to jeden z najlepszych systemów na rynku, dający ogromne możliwości tworzenia animacji i komunikację po sieci Ethernet. Ale jakby nie patrzeć, taki system to też łakomy kąsek dla MOSowiczów, więc pewnie tylko kwestią czasu jest, kiedy zastąpimy go własnym rozwiązaniem.</p>
            </div>
            <div className="flex justify-center m-5">
                <img className="piwo-image w-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" src={laser5}></img>
            </div>
        </div>

    </>)
}