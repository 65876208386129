import arcadeMachine1 from "../assets/arcade-1.jpg"
import arcadeMachine2 from "../assets/arcade-2.jpg"

export default function ArcadeMachine() {
    return (<>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="">
                <br />
                <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">Arcade Machine</p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">Arcade Machine powstał, aby na chwilę przypomnieć użytkownikowi automaty do gier z przekroju lat 70, 80 i 90. W środku, albowiem znajduje się, oparty na RaspberryPi emulator (RetroPie), który zawiera w sobie gry ze najpopularniejszych konsol na świecie (Playstation 1, Nintendo 64 czy Atari). Dodatkowo, w środku „maszyny” znajduje się prawdziwy telewizor kineskopowy, dzięki czemu młodsi użytkownicy mogą poczuć, jakby naprawdę grali na automatach z tamtych lat.</p>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center m-5 mx-auto w-full max-w-screen-xl">
                <img className="w-full max-w-full mb-4 md:mb-0 md:mr-4 rounded-xl shadow-xl ring-1 ring-gray-400/10 md:w-1/2 md:-ml-4 lg:-ml-0" src={arcadeMachine1}></img>
                <img className="w-full max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 md:w-1/2 md:-ml-4 lg:-ml-0" src={arcadeMachine2}></img>
            </div>
        </div>
    </>)
}