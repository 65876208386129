import t16 from "../assets/t16.png"
import { useEffect, useState, useRef } from 'react';
import { getDownloadURL } from "firebase/storage";
import { getStorage, ref, listAll } from "firebase/storage";

import "../css/PIWOPlayer.css"

export default function PIWOPlayer() {

  const [animationsFromStorage, setAnimationsFromStorage] = useState([]);

  const fetchAnimationsFromStorage = async () => {
    try {
      const storage = getStorage();
      const directoryRef = ref(storage, 'animations/'); // Replace with your directory path
      const result = await listAll(directoryRef);

      // Filter files with .piwo7 extension and get their download URLs
      const filePromises = result.items
        .filter((itemRef) => itemRef.name.endsWith('.piwo7'))
        .map(async (fileRef, index) => {
          const url = await getDownloadURL(fileRef);
          return { id: index + 1, name: fileRef.name, url };
        });

      const piwo7Files = await Promise.all(filePromises);

      // Filter files with .piwo7 extension and get their download URLs
      const mp3FilePromises = result.items
        .filter((itemRef) => itemRef.name.endsWith('.mp3'))
        .map(async (fileRef, index) => {
          const url = await getDownloadURL(fileRef);
          return { id: index + 1, name: fileRef.name, url };
        });

      const mp3Files = await Promise.all(mp3FilePromises);

      // Create a map of .piwo7 and .mp3 files based on the common name
      const animationMap = piwo7Files.map((piwo7File) => {
        const baseNamePiwo7 = piwo7File.name.replace('.piwo7', ''); // Remove .piwo7 to get base filename
        const matchingMp3 = mp3Files.find((mp3File) => mp3File.name.replace('.mp3', '') === baseNamePiwo7);
        return {
          id: piwo7File.id, // Use name as the ID or something unique
          piwo7Name: piwo7File.name,
          piwo7Url: piwo7File.url,
          mp3Url: matchingMp3 ? matchingMp3.url : null // If no matching .mp3 found, set null
        };
      });

      setAnimationsFromStorage(animationMap);
    }
    catch (error) {
      console.error("Error fetching animations:", error);
    }

  };

  //converted code from old wordpress site in JS to React
  const bcgImg = new Image();
  bcgImg.src = t16;

  const windowsPos = [
    22, 78, 31, 23, 62, 78, 31, 23, 96, 78, 31, 23, 136, 78, 31, 23, 170, 78, 31,
    23, 211, 78, 31, 23, 244, 78, 31, 23, 284, 78, 31, 23, 318, 78, 31, 23, 360, 78, 31, 23, 394, 78, 31,
    23, 435, 78, 31, 23, 22, 114, 31, 23, 62, 114, 31, 23, 96, 114, 31, 23, 136, 114, 31, 23, 170, 114, 31,
    23, 211, 114, 31, 23, 244, 114, 31, 23, 284, 114, 31, 23, 318, 114, 31, 23, 360, 114, 31, 23, 394, 114,
    31, 23, 435, 114, 31, 23, 22, 150, 31, 23, 62, 150, 31, 23, 96, 150, 31, 23, 136, 150, 31, 23, 170, 150,
    31, 23, 211, 150, 31, 23, 244, 150, 31, 23, 284, 150, 31, 23, 318, 150, 31, 23, 360, 150, 31, 23, 394,
    150, 31, 23, 435, 150, 31, 23, 22, 184, 31, 23, 62, 184, 31, 23, 96, 184, 31, 23, 136, 184, 31, 23, 170,
    184, 31, 23, 211, 184, 31, 23, 244, 184, 31, 23, 284, 184, 31, 23, 318, 184, 31, 23, 360, 184, 31, 23,
    394, 184, 31, 23, 435, 184, 31, 23, 22, 220, 31, 23, 62, 220, 31, 23, 96, 220, 31, 23, 136,
    220, 31, 23, 170, 220, 31, 23, 211, 220, 31, 23, 244, 220, 31, 23, 284, 220, 31, 23, 318, 220, 31, 23,
    360, 220, 31, 23, 394, 220, 31, 23, 435, 220, 31, 23, 22, 254, 31, 23, 62, 254, 31, 23, 96, 254, 31, 23,
    136, 254, 31, 23, 170, 254, 31, 23, 211, 254, 31, 23, 244, 254, 31, 23, 284, 254, 31, 23, 318, 254, 31,
    23, 360, 254, 31, 23, 394, 254, 31, 23, 435, 254, 31, 23, 22, 290, 31, 23, 62, 290, 31, 23, 96, 290, 31, 23, 136,
    290, 31, 23, 170, 290, 31, 23, 211, 290, 31, 23, 244, 290, 31, 23, 284, 290, 31, 23, 318, 290, 31, 23, 360,
    290, 31, 23, 394, 290, 31, 23, 435, 290, 31, 23, 22, 324, 31, 23, 62, 324, 31, 23, 96, 324, 31, 23, 136,
    324, 31, 23, 170, 324, 31, 23, 211, 324, 31, 23, 244, 324, 31, 23, 284, 324, 31, 23, 318, 324, 31, 23, 360,
    324, 31, 23, 394, 324, 31, 23, 435, 324, 31, 23, 22, 360, 31, 23, 62, 360, 31, 23, 96, 360, 31, 23, 136,
    360, 31, 23, 170, 360, 31, 23, 211, 360, 31, 23, 244, 360, 31, 23, 284, 360, 31, 23, 318, 360, 31, 23, 360,
    360, 31, 23, 394, 360, 31, 23, 435, 360, 31, 23, 22, 395, 31, 23, 62, 395, 31, 23, 96, 395, 31, 23, 136,
    395, 31, 23, 170, 395, 31, 23, 211, 395, 31, 23, 244, 395, 31, 23, 284, 395, 31, 23, 318, 395, 31, 23, 360,
    395, 31, 23, 394, 395, 31, 23, 435, 395, 31, 23
  ];

  const canvaRef = useRef();

  //x and y of FrameWidget
  const xRef = useRef(1);
  const yRef = useRef(1);

  const frameListRef = useRef([]);
  const durationListRef = useRef([]);
  const timeTabRef = useRef([]);

  function repaint(x, y, frame) {
    let idx = 0;
    for (let yPos = 0; yPos < y; yPos++) {
      for (let xPos = 0; xPos < x; xPos++) {
        const color = frame[yPos][xPos].toString(16);
        canvaRef.current.getContext('2d').fillStyle = "#" + "000000".substr(color.length) + color;
        canvaRef.current.getContext('2d').fillRect(windowsPos[idx], windowsPos[idx + 1], windowsPos[idx + 2], windowsPos[idx + 3]);
        idx += 4;
      }
    }
    canvaRef.current.getContext('2d').drawImage(bcgImg, 0, 0);
  }

  function loadAnimation(data) {

    const audio = document.getElementById("audio");

    //pause audio if it's already playing
    if (!audio.paused) {
      audio.pause();
    }

    //check header
    const header = data.substr(0, 11);
    if (header !== 'PIWO_6_FILE' && header !== 'PIWO_7_FILE') {
      alert("To nie jest plik .piwo");
      return;
    }

    //filter only numbers from file
    data = data.match(/[0-9]+/g);

    //take dimensions of animation
    const x = parseInt(data[1]);
    const y = parseInt(data[2]);
    if (x !== 12 || y !== 10) {
      return alert("Animacja musi mieć wymiary 12x10");
    }

    //render full black frame
    xRef.current = x;
    yRef.current = y;

    const canvas = document.getElementById("Screen");

    audio.style.width = canvas.width + 'px';
    let idx = 2;
    let firstFrame = true;
    let cont = true;

    let frame = [];
    let frameList = [];
    let durationList = [];

    //read frames with pixels and duration of frames
    while (cont) {
      idx++;
      if (idx >= data.length) {
        cont = false;
        break;
      }

      durationList.push(parseInt(data[idx]));

      frame = [];

      for (let i = 0; i < y; i++) {
        frame[i] = [];
      }

      for (let r = 0; r < y; r++) {
        for (let i = 0; i < x; i++) {
          idx++;
          frame[r][i] = parseInt(data[idx]);
        }
      }

      if (firstFrame) {
        repaint(x, y, frame);
        firstFrame = false;
      }

      frameList.push(frame);
    }

    durationListRef.current = durationList;
    frameListRef.current = frameList;
  }

  function loadAnimationFromFile(input) {
    const files = input.files;
    if (files.length < 1) return false;
    const file = files[0];
    if (file.size > 3 * 1024 * 1024) return false;
    const reader = new FileReader();
    reader.onloadend = function (evt) {
      if (evt.target.readyState !== FileReader.DONE) return;
      loadAnimation(evt.target.result);
    };
    reader.readAsBinaryString(file);
  }

  function loadAudioFromFile(input) {
    const files = input.files;
    if (files.length < 1) return false;
    const audio = document.getElementById("audio");
    if (!audio.paused) {
      audio.pause();
    }
    audio.src = URL.createObjectURL(input.files[0]);
  }

  function loadAnimationFromURL(url) {
    fetch(url)
      .then(response => response.text())
      .then(data => loadAnimation(data))
      .catch(error => console.error('Error loading animation:', error));
  }

  function loadAudioFromURL(url) {
    const audio = document.getElementById("audio");

    if (audio) {
      audio.src = url;
    }
  }

  useEffect(() => {
    fetchAnimationsFromStorage();

    bcgImg.onload = function () {
      InitPlayer();
    };
  }, []);

  function InitPlayer() {
    const canvas = document.getElementById('Screen');

    const audio = document.getElementById("audio");

    canvas.width = 489;
    canvas.height = 493;

    audio.onplay = function () {
      calcTimeTab();
      requestAnimationFrame(AVsync);
    }

    audio.style.width = '489px';

    const initialArray = Array.from({ length: 10 }, () => Array(12).fill(0));
    repaint(12, 10, initialArray);
  }

  function calcTimeTab() {
    let timeTab = [];
    timeTab[0] = 0;

    for (let i = 1; i < frameListRef.current.length; i++) {
      timeTab[i] = timeTab[i - 1]  + durationListRef.current[i - 1] / 1000;
    }
    timeTabRef.current = timeTab;
    
    requestAnimationFrame(AVsync);
  }

  function AVsync() {
    const audio = document.getElementById("audio");
    if (audio.paused) return;
    let time = audio.currentTime;
    let idx = 0;

    for (let i = 1; i < timeTabRef.current.length; i++) {
      if (time >= timeTabRef.current[i]) idx = i;
    }

    repaint(xRef.current, yRef.current, frameListRef.current[idx]);

    requestAnimationFrame(AVsync);
  }

  function loadPiwo7AndMp3File(index) {
    if(index === ""){
      return;
    }
    loadAnimationFromURL(animationsFromStorage[index].piwo7Url);
    loadAudioFromURL(animationsFromStorage[index].mp3Url);
  }

  return (
    <>
      <div id="info" className="content">
        <p className="mt-2 text-3xl font-bold tracking-tight text-green-500 dark:text-cyan-400 sm:text-4xl">P.I.W.O. Simulator</p>
      </div>
      <div id="info" className="content">
        <p className="mt-2 text-xl tracking-tight text-black dark:text-white">Uwaga: Player odtwarza jedynie animacje w formacie 12x10</p>
      </div>
      <div className="content">
        <canvas ref={canvaRef} id="Screen" />
      </div>
      <div className="content">
        <audio id="audio" controls={true} />
        <br />
      </div>
      <div className="choice content dark:text-white">
        <h6>Wybierz gotową animację:</h6>
      </div>
      <div className="choice content">
        <select onChange={(e) => loadPiwo7AndMp3File(e.target.value)}>
          <option defaultValue={"--Wybierz--"}></option>
          {animationsFromStorage.map((item, index) => (
            <option key={item.id} value={index}>{item.piwo7Name}</option>
          ))}
        </select>
      </div>
      <div className="content">
        <h6 className="dark:text-white">Wybierz animację z dysku:</h6>
      </div>
      <div className="choice content dark:text-white">
        Wybierz plik .piwo7 <input className="apply-button" type="file" accept=".piwo7,.piwo6" onChange={(e) => loadAnimationFromFile(e.target)} />
      </div>
      <div className="choice content dark:text-white">
        Wybierz plik .mp3 <input className="apply-button" type="file" accept=".mp3" onChange={(e) => loadAudioFromFile(e.target)} />
      </div>
    </>
  );
}
