import { useState } from "react";
import unknown from "../assets/unknown.png"
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

import style from "../css/markdown-styles.module.css"

export default function Blog() {

  const [blogPosts, setBlogPosts] = useState([]);

  const fetchBlogPosts = async () => {
    const projectId = '64536577'; // Replace with your GitLab project ID
    const branch = 'main'; // The branch to fetch from
    const gitlabToken = process.env.REACT_APP_BLOG_API_KEY; // Optional: Your GitLab access token

    const url = `https://gitlab.com/api/v4/projects/${projectId}/repository/tree?recursive=true&per_page=100&ref=${branch}`;

    try {
      const response = await fetch(url, {
        headers: {
          'PRIVATE-TOKEN': gitlabToken, // Include token if needed
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch file from GitLab');
      }

      const files = await response.json();

      const markdown = files.filter(file => file.path.endsWith('.md'));
      const imageFiles = files.filter(file =>
        file.path.endsWith('.jpg') || file.path.endsWith('.png') || file.path.endsWith('.gif')
      );

      //md files
      const promises = markdown.map(async (element, index) => {
        const filename = element.path;
        const fileUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/files/${filename}?ref=main`;
        const response = await fetch(fileUrl, {
          headers: {
            'PRIVATE-TOKEN': gitlabToken, // Include token if needed
          },
        });

        const fileData = await response.json(); // Parse JSON to get the file data
        const base64Content = fileData.content; // Get base64 encoded content
        const decodedContent = new TextDecoder("utf-8").decode(Uint8Array.from(atob(base64Content), c => c.charCodeAt(0)));

        //parse content of .md file
        const authorMatch = decodedContent.match(/# Author\s*(.+)/);
        const topicMatch = decodedContent.match(/# Topic\s*(.+)/);
        const dateMatch = decodedContent.match(/# Date\s*(.+)/);
        const contentMatch = decodedContent.match(/# Content\s*([\s\S]*)/);

        const firstSentence = contentMatch[1].split(/[.!?]/)[0].trim() + " (...)";

        return {
          id: index,
          filename,
          firstSentence,
          author: authorMatch[1],
          topic: topicMatch[1],
          creationDate: dateMatch[1],
          content: contentMatch[1]
        };
      });

      const results = await Promise.all(promises);

      results.map((post) => {
        const { updatedContent, filenames } = replaceImageFilenamesWithUrls(post.content, imageFiles, gitlabToken);
        post.content = updatedContent;
      });

      //sort from oldest to newest
      results.sort((a, b) => {
        const dateA = new Date(a.creationDate.split('-').reverse().join('-'));
        const dateB = new Date(b.creationDate.split('-').reverse().join('-'));
    
        return dateB - dateA;
      });

      setBlogPosts(results);

    } catch (error) {
      console.error('Error fetching Markdown:', error);
    }
  }

  const replaceImageFilenamesWithUrls = (str, imageFiles) => {
    const namespace = 'sknMOS';
    const project = 'Website Posts';
    // Extract image URLs from the content
    const imageUrls = str.match(/!\[.*?\]\((.*?)\)/g) || [];
    const filenames = imageUrls.map(url => url.match(/\((.*?)\)/)[1]);

    // Create a map of filename to URL
    const filenameToUrl = imageFiles.reduce((acc, element) => {
      const filename = element.path;
      const fileUrl = `https://gitlab.com/${namespace}/${project}/-/raw/main/${filename}?ref_type=heads`;
      acc[filename] = fileUrl;
      return acc;
    }, {});

    // Replace filenames in the content with URLs
    const updatedContent = str.replace(/!\[.*?\]\((.*?)\)/g, (match, filename) => {
      const url = filenameToUrl[filename] || filename;
      return match.replace(filename, url);
    });

    return { updatedContent, filenames };
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const [open, setOpen] = useState(false);
  const [post, setPost] = useState({});

  const openPost = async (post) => {
    setPost(post)
    setOpen(true);
    if(localStorage.getItem("darkMode") === true){
      document.documentElement.classList.add("dark");
    }
  }

  return (<div className="bg-white dark:bg-slate-800 card">

    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen h-screen flex justify-center items-center">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-auto max-h-[calc(100vh-4rem)] rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white dark:bg-slate-700 w-full flex flex-col justify-between">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="flex items-center gap-x-4 text-xs mt-4">
                    <time className="text-gray-500 dark:text-gray-400">
                      {post.creationDate}
                    </time>
                    <a
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.topic}
                    </a>
                  </div>
                  <div className="flex-grow dark:text-white flex justify-center items-center p-4">
                    <ReactMarkdown className={style.reactMarkDown} children={post.content} />
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4 p-4">
                    <img alt="" src={unknown} className="h-10 w-10 rounded-full bg-gray-50" />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a className="dark:text-white"> 
                          <span className="absolute inset-0" />
                          {post.author}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>

    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">MOS-Blog</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-gray-400">
          Sprawdź co się dzieje w T4!
        </p>
      </div>
      <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {blogPosts.map((post) => (
          <article key={post.id} className="flex flex-col items-start bg-gray-50 dark:bg-slate-600 p-4 rounded-lg shadow-md">
            <div className="flex items-center gap-x-4 text-xs">
              <time className="text-gray-500 dark:text-gray-400">
                {post.creationDate}
              </time>
              <a
                className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
              >
                {post.topic}
              </a>
            </div>
            <div className="group relative mt-8">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a>
                  <span className="absolute inset-0" />
                </a>
              </h3>

              <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600"></p>
              <p className="dark:text-white">{post.firstSentence}</p>

            </div>

            <button onClick={() => openPost(post)} className="inline-flex items-center rounded-md bg-green-50 dark:bg-cyan-50 px-2 py-1 text-xs font-medium text-green-500 dark:text-cyan-400 ring-1 ring-inset ring-blue-700/10">Czytaj dalej...</button>

            <div className="relative mt-8 flex items-center gap-x-4">
              <img alt="" src={unknown} className="h-10 w-10 rounded-full bg-gray-50" />
              <div className="text-sm leading-6">
                <p className="font-semibold text-gray-900">
                  <a className="dark:text-white">
                    <span className="absolute inset-0" />
                    {post.author}
                  </a>
                </p>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  </div>);
}